.mainContainer {
  /* width:100% */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* min-width: 610px; */
  margin-bottom: 10px;
  padding-right: 20px;
  width:102%
}

.header {
  border-bottom: .5px solid #000;
  padding-bottom: 2px;
  margin-bottom: 20px;
}